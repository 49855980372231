import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const VideoPlayer = ({ apiKey }) => {
  const { id } = useParams();
  const [description, setDescription] = useState('');

  useEffect(() => {
    const fetchVideoDetails = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/videos?key=${apiKey}&id=${id}&part=snippet`
        );
        const data = await response.json();
        if (data.items && data.items.length > 0) {
          const fullDescription = data.items[0].snippet.description;
          const limitedDescription = fullDescription.length > 200 
            ? fullDescription.slice(0, 200) + '...' 
            : fullDescription;
          setDescription(limitedDescription);
        } else {
          setDescription('Description not available.');
        }
      } catch (error) {
        console.error('Error fetching video details:', error);
        setDescription('Error fetching description.');
      }
    };

    fetchVideoDetails();
  }, [id, apiKey]);

  return (
    <div className='bg-[#0A0A0A] p-4 flex flex-col items-center mm:px-8 lg:px-[14%] py-[50px]'>
      <div className="relative w-full h-0" style={{ paddingBottom: '56.25%' }}>
        <iframe
          className="absolute top-0 left-0 w-full h-full"
          src={`https://www.youtube.com/embed/${id}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube video player"
        ></iframe>
      </div>
      <div className="mt-4 text-white font-Mont text-[14px] mm:text-center lg:text-left">
        <p>{description}</p>
      </div>
    </div>
  );
}

export default VideoPlayer;
