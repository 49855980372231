import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Carousel.css'; 
import PrevArrow from './PrevArrow';
import NextArrow from './NextArrow';

const YouTubeThumbnailsCarousel = ({ apiKey, playlistId, sectionTitle }) => {
  const [videos, setVideos] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVideos = async (pageToken = '') => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/playlistItems?key=${apiKey}&playlistId=${playlistId}&part=snippet&maxResults=50&pageToken=${pageToken}`
        );
        const data = await response.json();
        if (data.items && Array.isArray(data.items)) {
          setVideos(prevVideos => [...prevVideos, ...data.items]);
          setNextPageToken(data.nextPageToken);
        } else {
          setError('Nenhum vídeo encontrado.');
        }
      } catch (error) {
        console.error('Erro ao buscar vídeos:', error);
        setError('Erro ao buscar vídeos.');
      }
    };

    fetchVideos();

    if (nextPageToken) {
      fetchVideos(playlistId, nextPageToken);
    }
  }, [apiKey, playlistId, nextPageToken]);

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    draggable: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className="lg:pt-[70px] mm:py-[50px]">
      <div className="flex justify-center items-center">
        <div className='w-full max-w-[73%]'>
          <div className='text-left self-start font-Sora font-bold mm:text-[23px] text-[42px] bg-clip-text text-transparent bg-gradient-to-r from-[#FFC510] to-[#608A06]'>
            {sectionTitle}
          </div>
          
          {error && <p className="text-red-500 text-center">{error}</p>}
          <Slider {...settings}>
            {videos.map((video) => {
              const thumbnailUrl = video?.snippet?.thumbnails?.high?.url || video?.snippet?.thumbnails?.medium?.url || video?.snippet?.thumbnails?.default?.url || ''; 
              return (
                <div key={video.snippet.resourceId.videoId} className="p-2">
                  <div className='thumbnail-container bg-[#202020] hover:scale-105 transition-all duration-200 ease-in-out '>
                    {thumbnailUrl ? (
                      <Link to={`/video/${video.snippet.resourceId.videoId}`}>
                        <img
                          src={thumbnailUrl}
                          alt={video.snippet.title}
                          className="w-full h-full object-cover"
                        />
                      </Link>
                    ) : (
                      <p className="text-center text-white ">Nenhuma imagem disponível</p>
                    )}
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default YouTubeThumbnailsCarousel;
