import React from 'react';
import logomz from "../assets/logomz.png";

const Start = () => {
  return (
    <div className='h-[64px] w-full bg-white flex lg:px-[14%] justify-between items-center mm:flex-col lg:flex-row mm:h-auto mm:px-[5%] mm:py-4'>
        <div className='mm:mb-3 lg:mb-0'>
            <img src={logomz} alt="" className='h-auto mm:w-[100px]' draggable="false" />
        </div>
        <div className='text-black font-Mont font-medium text-[16px] mm:text-[14px] mm:mb-3 lg:mb-0'>
            O melhor atendimento começa aqui!
        </div>
        <div>
            <a href="https://megazap.com/" target='_blank' rel="noreferrer" draggable="false">
                <button className='rounded-full w-[170px] py-2 bg-[#096A9F] hover:bg-[#0f5880] trnsition-all duration-200 ease-in-out text-white font-Mont font-medium text-[12px] mm:w-[140px] mm:text-[12px]' >
                    Saiba mais
                </button>
            </a>
        </div>
    </div>
  );
}

export default Start;
