import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home"
import Play from "./pages/Play"


function App() {
  const apiKey = 'AIzaSyA8zKquZmHBaXTvtPwqgPeES4BjBKS_PQw';

  return (
    <div>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/video/:id" element={<Play apiKey={apiKey}/>} />
           
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;