import React from 'react';
import { MdArrowRight } from "react-icons/md";

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="absolute  top-1/2 mm:right-[-10px] lg:right-[-20px] transform -translate-y-1/2 bg-[#FFC510] text-white mm:p-0 md:p-1 lg:p-2 shadow-lg hover:bg-gray-600"
      onClick={onClick}
    >
    <MdArrowRight size={28} />
    </button>
  );
};

export default NextArrow;
