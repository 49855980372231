import React from 'react';
import { MdArrowLeft } from "react-icons/md";

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button
      className="absolute z-30 top-1/2 mm:left-[-10px] lg:left-[-25px] transform -translate-y-1/2 bg-[#FFC510] text-white mm:p-0 md:p-1 lg:p-2  shadow-lg hover:bg-gray-600"
      onClick={onClick}
    >
      <MdArrowLeft size={28} />
    </button>
  );
};

export default PrevArrow;
