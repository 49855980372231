import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#1F261E]/80 text-white font-Sora pt-8 ">
      <div className="container mx-auto text-center ">
        <div className="text-4xl md:text-5xl lg:text-6xl font-bold">
          BRUNO ALVES
        </div>
        <div className="text-lg md:text-xl lg:text-2xl mb-4">
          Consultoria especializada em Pecuária Leiteira
        </div>
       
        
      </div>
      <div className='bg-gradient-to-r from-[#7f9608] to-[#e6bb0e] h-2'>

        </div>
    </footer>
  );
};

export default Footer;
