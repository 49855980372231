import Cover from "../components/Cover"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Example from "../components/Example"
import Start from "../components/Start"
import Eps from "../components/Eps"


function Home() {
  const apiKey = 'AIzaSyA8zKquZmHBaXTvtPwqgPeES4BjBKS_PQw';
  return (
    <div>
      <div className="bg-hero bg-cover xx:bg-cover lg:bg-[length:2000px_1000px] bg-no-repeat ">
        <Header/> 
        <Cover/>
      </div>
     <div className="bg-gradient-to-r from-[#e9efdb] to-white">
        <Start/>
        <Eps apiKey={apiKey}  playlistId="PLauQ9Eh2ro-p6Ipl1s5PhipRrGuvnhVpU" sectionTitle='Convidados'/>
        <Example apiKey={apiKey} playlistId="PLauQ9Eh2ro-p6Ipl1s5PhipRrGuvnhVpU"/>
     </div>
        <Footer/>  
    </div>
  );
}

export default Home;
