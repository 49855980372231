import React, { useState, useEffect } from 'react';

const VideoSection = ({ apiKey, playlistId }) => {
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    const fetchLatestVideo = async () => {
      const playlistApiUrl = `https://www.googleapis.com/youtube/v3/playlistItems?key=${apiKey}&playlistId=${playlistId}&part=snippet&maxResults=1`;

      try {
        // Busca o vídeo mais recente da playlist
        const response = await fetch(playlistApiUrl);
        const data = await response.json();

        if (data.items && data.items.length > 0) {
          const latestVideoId = data.items[0].snippet.resourceId.videoId;
          setVideoUrl(`https://www.youtube.com/embed/${latestVideoId}`);
        }
      } catch (error) {
        console.error('Error fetching the video: ', error);
      }
    };

    fetchLatestVideo();
  }, [apiKey, playlistId]);

  return (
    <div className="flex flex-col md:flex-row items-center justify-between  px-[14%] lg:pt-[50px] mm:pb-[40px]">
      <div className="md:w-[35%] text-center md:text-left mb-6 md:mb-0 flex items-center justify-center w-auto">
        <div className='w-fit'>
          <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#FFC510] to-[#608A06]">
            Veja o que 
          </div>
          <div className="text-xl md:text-2xl text-gray-700 font-Sora leading-none pb-1">espera por você!</div>
          <div className='uppercase font-Sora text-sm'>aperte o play</div>
        </div>
      </div>
      <div className="relative flex justify-center items-center w-full h-fit" style={{ paddingBottom: '55.25%' }}>
        {videoUrl ? (
          <iframe
          className="absolute top-[10%] left-0 mm:w-full lg:w-[92.7%] mm:h-[100%] lg:h-[70%] rounded-3xl"
          src={videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube video player"
        ></iframe>
        ) : (
          <p>Carregando...</p>
        )}
      </div>
    </div>
  );
};

export default VideoSection;
