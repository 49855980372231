import React from 'react'
import Header from "../components/Header"
import VideoPlayer from "../components/VideoPlayer"
import Footer from "../components/Footer"
import Eps from "../components/Eps"


const Play = () => {
  const apiKey = 'AIzaSyA8zKquZmHBaXTvtPwqgPeES4BjBKS_PQw';
  return (
    <div>
      <Header/>
      <VideoPlayer apiKey={apiKey} />
      <Eps apiKey={apiKey} playlistId="PLauQ9Eh2ro-p6Ipl1s5PhipRrGuvnhVpU" sectionTitle='Epsódios relacionados'/>
      <Footer/>
    </div>
  )
}

export default Play